export default [
  "STUDENT",
  "PRIMARY_SCHOOL",
  "SECONDARY_SCHOOL",
  "HIGH_SCHOOL",
  "COLLAGE",
  "UNIVERSITY",
  "MASTER",
  "PHD"
];
